<template>
    <div id="apps">
        <div class="logiins clear">
            <div class="inform clear">
                <h3>商家登录</h3>
                <div class="label clear"><img src="../assets/logins.png"/><input type="text" placeholder="请输入登录帐号"
                                                                                 v-model="username"/></div>
                <div class="label clear"><img src="../assets/mima.png"/><input type="password" placeholder="请输入登录密码"
                                                                               v-model="password"/></div>
                <button @click="submit">登录</button>
            </div>
        </div>
    </div>
</template>

<script>
  import {Toast} from 'vant';
  import {shopLogin} from "@/api/user";
  export default {
    name: 'app',
    components: {},
    data () {
      return {
        toastText: '',
        toastShow: '',
        username: '',
        password: '',
      }
    },
    methods: {
      toast (str) {
        let v = this;
        v.toastText = str;
        v.toastShow = true;
        setTimeout(function () {
          v.toastShow = false
        }, 2500)
      },
      submit() {
        if (this.username.length <= 0) {
          Toast('请输入登录账号');
          return
        }
        if (this.password.length <= 0) {
          Toast('请输入登录密码')
          return
        }
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中'
        });
        shopLogin({
          username: this.username,
          password: this.password
        }).then(data => {
          if (data.success == true) {
            this.$store.commit('SET_TOKEN', data.data.token);
            this.$store.commit('SET_INFO', data.data);
            this.$store.commit('SET_TYPE', 'shop');
            window.location.href = '/sjhexiao';
            Toast.clear()
          } else {
            Toast(data.msg)
          }
        }).finally(() => {
        })
      }
    },
    created() {
      // if (this.$store.getters.userinfo) {
      //   window.location.href = '/sjhexiao'
      // }
    }
  }
</script>

<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .logiins {
        height: 100vh;
        overflow: hidden;
        width: 100%;
        background: url('../assets/timg.jpeg') center center no-repeat;
    }

    .logiins .inform {
        width: 84%;
        margin: 30% auto;
        background: #fff;
        border-radius: 5px;
    }

    .logiins .inform h3 {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        margin: 6% auto;
        display: block;
    }

    .logiins .inform .label {
        border-bottom: solid 1px #F5F5F5;
        width: 84%;
        margin: 4% auto;
        padding-bottom: 5px;
    }

    .logiins .inform .label img {
        display: inline-block;
        float: left;
        width: 18px;
    }

    .logiins .inform .label input {
        display: inline-block;
        float: left;
        margin-left: 3px;
        border: none;
        outline: none;
    }

    .logiins .inform button {
        font-size: 14px;
        text-align: center;
        margin: 6% auto 7% auto;
        display: block;
        border-radius: 30px;
        line-height: 30px;
        background: linear-gradient(to right, #F05C4A, #F98E49);
        border: none;
        display: block;
        width: 83%;
        color: #fff;
    }

    input::-webkit-input-placeholder {
        font-size: 11px;
        color: #888;
    }

    .toast {
        position: fixed;
        z-index: 2000;
        left: 50%;
        top: 45%;
        transition: all .5s;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        border-radius: 5px;
        color: #FFF;
        background: rgba(17, 17, 17, 0.7);
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        max-width: 150px;
    }
</style>
